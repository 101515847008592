.image-gen-container {
    padding: 20px;
    background-color: #333;
    color: #f8f9fa;
    text-align: center;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.form-container {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 15px;
}

textarea, input, select {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

button {
    background-color: rgb(68 101 219);
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: rgb(68 101 219);
}

.status {
    font-size: 1.2rem;
    margin: 20px 0;
}

.image-container img {
    max-width: 100%;
    height: auto;
    border: 2px solid #ccc;
    border-radius: 5px;
}

.history {
    margin-top: 30px;
    text-align: left;
}

.history ul {
    list-style: none;
    padding: 0;
}

.history li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.history img {
    max-width: 100px;
    height: auto;
    border: 1px solid #ddd;
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .image-gen-container {
        padding: 10px;
    }

    button {
        width: 100%;
    }
}